// Global packages and components
import { clsx } from 'clsx'
import { ReactNode } from 'react'

// Types
interface Props {
  /**
   * Card padding size
   */
  padding?: 'xs' | 'sm' | 'md' | 'lg'
  /**
   * Card content
   */
  children: ReactNode
}

// Main export
const Card = ({ padding = 'md', children }: Props) => {
  return (
    <div
      className={clsx('rounded-2xl bg-gray-100 p-5', {
        'p-5': padding == 'xs',
        'px-5 py-7 md:px-6 md:py-8 lg:px-8 lg:py-10': padding == 'sm',
        'px-5 py-7 md:px-8 md:py-10 lg:px-10 lg:py-12': padding == 'md',
        'px-5 py-7 md:px-10 md:py-12 lg:px-12 lg:py-14': padding == 'lg',
      })}
    >
      {children}
    </div>
  )
}

export default Card
